/** ----------------------------------------
    Node
 ---------------------------------------- */

import EventEmitter from 'events';

/* ----------------------------------------
    Lodash
---------------------------------------- */

import { assign } from 'lodash';

/** ----------------------------------------
     Cookies Configuration
 ---------------------------------------- */

import { consent } from '@config';

/** ----------------------------------------
     Helper
 ---------------------------------------- */

import { Inject } from '@helpers/Inject';
const inject = new Inject();

/** ----------------------------------------
     State
 ---------------------------------------- */

import state from '../store/state';

/** ----------------------------------------
    Cookies Consent
 ---------------------------------------- */

export class Consent extends EventEmitter {

    /** ----------------------------------------
         Constructor
     ---------------------------------------- */

    constructor() {
        super();
        this._events = {
            init: {},
            change: {}
        };
        this._init();
        this._promise = '';
    }

    /** ----------------------------------------
         Inject Files
     ---------------------------------------- */

    _injectFiles() {
        const path = '//cookieconsent.vruchtvlees.com/v1/';
        inject.script = path + 'cookieconsent.min.js';
        inject.style = path + 'cookieconsent.min.css';
    }

    /** ----------------------------------------
         Run Cookie Consent
     ---------------------------------------- */

    async _injectScript(exe) {
        return new Promise(resolve => {
            inject.script.then(() => {
                const checkAsync = setInterval(() => {
                    const cookieConsent = window.cookieconsent;

                    if(cookieConsent) {
                        clearInterval(checkAsync);
                        cookieConsent.initialise(consent.settings(exe, state.cookieBanner));

                        resolve({
                            consent: cookieConsent,
                            status: consent.status,
                            settings: consent.settings(exe, state.cookieBanner)
                        });
                    }

                }, 50);
            });
        })
    }

    stateChange(exe) {
        return this._injectScript(exe);
    }

    /** ----------------------------------------
         Initialize
     ---------------------------------------- */

    _init() {
        this._injectFiles();
    }

}