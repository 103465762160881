/** ----------------------------------------
    AppJob
 ---------------------------------------- */

import AppGenerator from '@generator/app';
import { GeneratorHelper } from '@fruitpackage/helpers';

export default class AppJob  {
    vue: any;

    protected constructor(vue: any, setting: any) {
        this.vue = vue;
    }

    public init() {
        const gen = AppGenerator(this.vue);
        GeneratorHelper.runGenerator(gen);
    }
}