
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from "vue-property-decorator";
import { StoreMixin } from "@mixin/store";
import { app } from "@config";
import { mapState } from "vuex";
import SliderFooter from "../../../fruitpackage/components/SliderFooter.vue";
import InputField from "@part/elements/InputField.vue";
import FacebookInvSvg from "@svg/FacebookInv.vue";
import TiktokSvg from "@svg/Tiktok.vue";

import InstagramSvg from "@svg/Instagram.vue";
import ArrowForwardSvg from "@svg/ArrowForward.vue";
import axios from "axios";

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    InputField,
    FacebookInvSvg,
    TiktokSvg,
    InstagramSvg,
    ArrowForwardSvg,
    SliderFooter
  },
  mixins: [StoreMixin],
  data() {
    return {
      email: ""
    };
  },
  computed: {
    ...mapState(["navData"])
  },
  methods: {
    subscribeToNewsletter() {
      // send request to subscribe to the newsletter
      axios
        .post(`${app.api.auth}/subscribe`, {
          email: this.email
        })
        .then(response => {
          this.dispatch(
            "setToastMessage",
            "Je bent ingeschreven met e-mailadres " + this.email + "!"
          );
          this.email = "";
        })
        .catch(error => {
          let message =
            error.response.status == 422
              ? '"' + this.email + '" is geen geldige invoer!'
              : "Whoops! Er ging iets fout!";
          this.dispatch("setToastData", {
            message: message,
            button: "",
            link: "",
            color: "#e7325d"
          });
          console.log(`🔥${error}🔥`);
        });
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class PageFooter extends Vue {}
