
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { AppStore } from '@interface/store/App';
import { app } from '@config';
import Logo from '@part/elements/Logo';
import UserDropdown from '@part/elements/UserDropdown';
import MenuButton from '@part/elements/MenuButton';
import FacebookInvSvg from '@svg/FacebookInv.vue';
import InstagramSvg from '@svg/Instagram.vue';
import TiktokSvg from '@svg/Tiktok.vue';
import ArrowForwardSvg from '@svg/ArrowForward.vue';
import UserSvg from '@svg/User.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        MenuButton,
        FacebookInvSvg,
        InstagramSvg,
        TiktokSvg,
        ArrowForwardSvg,
        UserSvg
    },
    props: {
        visible: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        ...mapState(['navData'])
    },
    methods: {
        toggleMenu() {
            this.$emit('clicked', '');
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class PageHeader extends Vue {}
