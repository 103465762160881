
/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import PageView from '@part/layout/PageView.vue';

import CrossCircleSvg from '@svg/CrossCircle.vue';
import ExternalSvg from '@svg/External.vue';
import MyNightButton from '@part/elements/MyNightButton.vue';
import PinGhostSvg from '@svg/PinGhost.vue';
import ClockSvg from '@svg/Clock.vue';
import SurpriseSvg from '@svg/Surprise.vue';
import { app } from '@config';
import axios from 'axios';
import anime from 'animejs';
/* ----------------------------------------
        Component
    ---------------------------------------- */

@Component({
  components: {
    CrossCircleSvg,
    ExternalSvg,
    MyNightButton,
    PinGhostSvg,
    ClockSvg,
    SurpriseSvg,
  },
  data() {
    return {
      isActive: false,
      isLoading: false,
      activeSupriseItem: {},
      overlayElement: null,
    };
  },
  computed: {
    showSurpriseBtn() {
      if (this.$route.name === 'programma' || this.$route.name === 'tentoonstellingen') {
        return false;
      }

      return true;
    },
  },
  mounted() {
    this.overlayElement = document.querySelector('.rndom-overlay');
  },
  methods: {
    setOverlayStatus() {
      if (this.isActive) {
        this.isActive = false;
        return;
      };
      this.isActive = true;
      this.getSupriseItem();
    },
    getSupriseItem() {
      const instance = axios.create({
        baseURL: app.api.auth,
        timeout: 2500,
        headers: {}
      });

      instance
        .get('/surpriseme')
        .then(response => {
          anime(({
            targets: '.rndom-overlay__dynamic',
            opacity: 0,
            easing: 'steps(1)'
          }));
          this.activeSupriseItem = response.data;
          return response.data;
        })
        .then(() => {
          setTimeout(() => {
            anime(({
              targets: '.rndom-overlay__dynamic',
              opacity: 1,
              easing: 'easeInQuint'
            }));
          }, 500);
        })
        .catch(error => {
          console.log(`🔥${error}🔥`);
        });
    }
  },
})

/* ----------------------------------------
        Export
    ---------------------------------------- */
export default class PageView extends Vue { }
