
/* ----------------------------------------

    ---------------------------------------- */
import { Component, Vue } from 'vue-property-decorator';

import anime from 'animejs';
/* ----------------------------------------
        Assets
    ---------------------------------------- */
import StrokeSvg from '@svg/Stroke.vue';
/* ----------------------------------------
        Component
    ---------------------------------------- */
@Component({
    data() {
        return {
            previousScrollTop: 0,
            drawLength: 0,
            totalLength: 0,
            tl: null,
            timeline: {
                tentoonstellingen: {
                    color: '#FDEDF1',
                    value: 'M1042.72,136.4c172.56-51.34,319.44-105.53,440.95,20.32,112,116,84,300,68,376-43.28,205.56-235.78,240.85-442.96,167.86-162.12-57.11-261.23,41.48-222,184.89,42.9,156.81-71.73,348.78-185,322.12-225.36-53.04-120,136.24-252.27,138.66-107.35,1.96-135.03-74.86-232.09,28.65-91.71,97.81-236.84-25.03-184.13-174.42,39.35-111.53,153.68-119.68,181.49-240.94,27.98-122.03-100.82-211.95-9.6-339.23,82.8-115.54,24.35-129.62,52.03-318.39,23.34-159.1,118.8-263.05,271.54-277.9,233.4-22.69,376,153.44,514.05,112.37Z'
                },
                'tentoonstellingen/:id': {
                    color: '#FDEDF1',
                    value: 'M1042.72,136.4c172.56-51.34,319.44-105.53,440.95,20.32,112,116,84,300,68,376-43.28,205.56-235.78,240.85-442.96,167.86-162.12-57.11-261.23,41.48-222,184.89,42.9,156.81-71.73,348.78-185,322.12-225.36-53.04-120,136.24-252.27,138.66-107.35,1.96-135.03-74.86-232.09,28.65-91.71,97.81-236.84-25.03-184.13-174.42,39.35-111.53,153.68-119.68,181.49-240.94,27.98-122.03-100.82-211.95-9.6-339.23,82.8-115.54,24.35-129.62,52.03-318.39,23.34-159.1,118.8-263.05,271.54-277.9,233.4-22.69,376,153.44,514.05,112.37Z'
                },
                programma: {
                    color: '#E9BBC6',
                    value: 'M715.08,221.35c138.05,41.07,176.69-19.25,227.14-62.57,279.31-239.84,544,110.69,436.62,309.62-36.34,67.55-146.22,268.79,0,336.09,195.03,89.04,140.45,178.88,176.57,332.89,28.41,121.12,100.12,53.74,175.77,135.56,107.55,116.31-80.26,254.28-164.53,150-42.56-52.66-50.57-161.62-157.31-150-243.19,26.47-290.54-93.05-321.04-205.35-30.5-112.29,43.34-232.61-212.69-321.65-256.03-89.04-286.28,31.78-316.22,163.64-153.71,676.72-888.63,25.41-304.55-203.77,114.76-45.03,200.21-14.41,166.5-186.07-33.71-171.66,121.19-349.73,293.75-298.39Z'
                },
                'programma/:id': {
                    color: '#E9BBC6',
                    value: 'M715.08,221.35c138.05,41.07,176.69-19.25,227.14-62.57,279.31-239.84,544,110.69,436.62,309.62-36.34,67.55-146.22,268.79,0,336.09,195.03,89.04,140.45,178.88,176.57,332.89,28.41,121.12,100.12,53.74,175.77,135.56,107.55,116.31-80.26,254.28-164.53,150-42.56-52.66-50.57-161.62-157.31-150-243.19,26.47-290.54-93.05-321.04-205.35-30.5-112.29,43.34-232.61-212.69-321.65-256.03-89.04-286.28,31.78-316.22,163.64-153.71,676.72-888.63,25.41-304.55-203.77,114.76-45.03,200.21-14.41,166.5-186.07-33.71-171.66,121.19-349.73,293.75-298.39Z'
                },
                home: {
                    color: '#FDEDF1',
                    value: 'M950.07,182.48c68.81-145.02,507.11-145.02,444.28,234.72-62.82,379.75,196.24,370.3,258.8,387.22,198.96,53.82,136.13,393.2-104.72,296.02-101.11-40.8-131.66-45.51-210.68,14.1-114.11,86.1-259.84,170.12-396.66-26.06-94.93-136.12-167.69-46.86-312.65-68.77-178.02-26.91-273.75,147.95-273.75,228.74,0,92.43-112.27,230.3-234.86,128.58-120.07-99.64-37.4-254.16,94.24-258.65,131.64-4.48,133.13-213.79,109.2-275.09-23.93-61.3-76.29-218.28-17.95-337.89,95.47-180.13,210.97-251.79,387.45-242.19,81.67,13.33,202.25,35.28,257.3-80.73Z'
                },
                museum: {
                    color: '#FDEDF1',
                    value: 'M226.95,671c-124.86-142.85-62.25-458.93,223.32-458.93,75.36,0,155.05,13.5,165.88-66.37,12.97-95.61,152.45-199.09,278.96-44.99,167.05,203.48,474.73,124.45,518.55,96.74,99.58-62.99,205.15,80.7,73.11,161.97-124.26,76.49-113.01,143.98-174.35,253.09-59.79,106.35-43.86,196.84-14.62,275.58,29.25,78.74,6.75,335.2-281.21,236.21-287.96-98.98-314.95,177.72-343.08,233.96-55.96,112.55-162.31,179.13-273.15,193.17-129.83,16.44-265.83-39.18-334.26-177.41-92.9-167.7-30.93-345.82,110.23-445.43,58.49-38.25,150.5-143.31,50.62-257.59Z'
                },
                'museum/:id': {
                    color: '#FDEDF1',
                    value: 'M226.95,671c-124.86-142.85-62.25-458.93,223.32-458.93,75.36,0,155.05,13.5,165.88-66.37,12.97-95.61,152.45-199.09,278.96-44.99,167.05,203.48,474.73,124.45,518.55,96.74,99.58-62.99,205.15,80.7,73.11,161.97-124.26,76.49-113.01,143.98-174.35,253.09-59.79,106.35-43.86,196.84-14.62,275.58,29.25,78.74,6.75,335.2-281.21,236.21-287.96-98.98-314.95,177.72-343.08,233.96-55.96,112.55-162.31,179.13-273.15,193.17-129.83,16.44-265.83-39.18-334.26-177.41-92.9-167.7-30.93-345.82,110.23-445.43,58.49-38.25,150.5-143.31,50.62-257.59Z'
                },
            }

        };
    },
    watch: {
        $route: function (to, from) {

            if (to !== from) {
                let sequence = [
                    this.timeline[from.name || 'home'],
                    this.timeline[to.name || 'home']
                ];
                let color = '#BBD6E9';
                if (sequence[1] && sequence[1].color) {
                    color = sequence[1].color;
                }
                const timeline =
                    anime.timeline({
                        easing: 'easeInOutExpo',
                        duration: 1000
                    });

                timeline.add({
                    targets: '.animated-line',
                    strokeDashoffset: [this.drawLength, 0],
                    stroke: color,
                    direction: 'alternate',
                    d: sequence,
                    easing: 'easeOutQuad',
                    loop: false
                });
            }

        }
    },
    mounted() {
        this.setupScrollListener();
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        setupScrollListener() {
            const path = document.querySelector('.animated-line');
            this.totalLength = path.getTotalLength();
            console.log(this.totalLength);
            path.style.strokeDasharray = this.totalLength;
            path.style.strokeDashoffset = this.totalLength;
            window.addEventListener('scroll', this.handleScroll);
        },
        handleScroll() {
            const path = document.querySelector('.animated-line');
            const scrollTop = window.scrollY;
            const docHeight = document.documentElement.scrollHeight - window.innerHeight;

            // Calculate the scroll percentage
            const scrollPercent = Math.min(scrollTop / docHeight, 1);
            let eraseLength;

            let halfScrollHeight = 0.5;
            if (window.innerHeight < 2400) {
                halfScrollHeight = 1;
            }

            if (scrollPercent <= halfScrollHeight) {
                // Erase the line from both sides
                const halfScrollPercent = scrollPercent / halfScrollHeight;
                eraseLength = this.totalLength * halfScrollPercent;
                path.style.strokeDasharray = `${this.totalLength - eraseLength} ${eraseLength}`;
                path.style.strokeDashoffset = eraseLength / 2;
            } else {
                // Grow the line back from both sides in reverse
                const halfScrollPercent = (scrollPercent - halfScrollHeight) / halfScrollHeight;
                eraseLength = this.totalLength * halfScrollPercent;
                path.style.strokeDasharray = `${eraseLength} ${this.totalLength - eraseLength}`;
                path.style.strokeDashoffset = (this.totalLength - eraseLength) / 2;
            }

            const translateY = scrollPercent * 500;
            path.style.transform = `translateY(-${translateY}px)`;

        }
    }
})
/* ----------------------------------------
        Export
    ---------------------------------------- */
export default class Stroke extends Vue { }
