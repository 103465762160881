/* ----------------------------------------
    App generator
---------------------------------------- */

import ApiCommunicator from '@service/ApiCommunicator';
import RoutesBuilder from '@service/RoutesBuilder';
import MenusBuilder from '@service/MenusBuilder';
import { Consent } from '@helpers/Consent';
import Vue from 'vue';
import { app } from '@config';

/* ----------------------------------------
    Set Cookie Consent
---------------------------------------- */

function initCookieConsent() {
    const consent = new Consent();

    const cookieState = state => {
        if(state === 'allow') {
            // ALLOW
            window[`ga-disable-${app.analytics.TRACKING_ID}`] = false;
        } else {
            // NOTHING
        }
    };

    consent.stateChange(cookieState);

    return true;
}

export default function * AppGenerator(vue: any) {
    const apiCommunicator = new ApiCommunicator(vue);
    const routesBuilder = new RoutesBuilder(vue);
    const menusBuilder = new MenusBuilder(vue);

    yield apiCommunicator.clearAppStore();
    yield apiCommunicator.fetchApp();
    yield routesBuilder.setRoutes();
    yield menusBuilder.setMenuItems();
    yield initCookieConsent();
}