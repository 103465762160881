
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState, mapMutations } from 'vuex';
// import { mapGetters } from 'vuex';
import SearchSvg from '@svg/Search.vue';
import Fuse from 'fuse.js';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  props: {
    inEventOverview: {
      type: Boolean
    },
    isFiltering: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: String
    },
    location: {
      type: String
    },
    inOverlay: {
      type: Boolean,
      default: false
    },
    loadingEvents: {
      type: Boolean,
      required: false,
      default: true
    },
    loadingMuseums: {
      type: Boolean,
      required: false,
      default: true
    },
    forFooter: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      userInput: '',
      searchInput: this.$store.state.searchQuery,

      fuseEventOptions: {
        isCaseSensitive: false,
        location: 0,
        distance: 200,
        threshold: 0.2,
        keys: [
          {
            name: 'title',
            weight: 0.5,
          },
          {
            name: 'category',
            weight: 0.2
          },
          {
            name: 'museum',
            weight: 0.3
          }
        ]
      },
      useMuseumOptions: {
        isCaseSensitive: false,
        ignoreLocation: true,
        threshold: 0.3,
        keys: [
          {
            name: 'name',
            weight: 0.9,
          }
        ]
      },
      fuse: null
    };
  },
  watch: {
    isFiltering: function() {
      if (this.inEventOverview) {
        this.$emit('filtering', false);
        this.setUserinputdata();
        return;
      }

      this.setSubmittedData();
    },
    loadingEvents: function() {
      this.initFuse();
      this.setSubmittedData();
    },
    loadingMuseums: function() {
      this.initFuseMuseums();
    },
    searchQuery: function() {
      this.searchInput = this.$store.state.searchQuery;
      if (!this.loadingEvents) this.setSubmittedData();
    }
  },
  components: {
    SearchSvg
  },
  computed: {
    ...mapState(['selectedFilters', 'unfilteredEvents', 'unfilteredMuseums', 'filteredEvents', 'filteredMuseums', 'searchQuery'])
  },
  methods: {
    ...mapMutations(['setFilteredEvents', 'setFilteredMuseums', 'setSearchQuery']),
    submit() {
      if (this.loadingEvents) {
        return;
      }
      if (this.userInput.length > 0) {
        this.setFilteredEvents(this.fuseEvents.search(this.userInput));
        this.$emit('searching', '');
      } else {
        this.setFilteredEvents(this.unfilteredEvents.data);
        this.$emit('searching', 'reset');
      }
    },
    searchWhileTyping() {
      this.$emit('filtering', true);
    },

    setUserinputdata() {
      if (this.loadingEvents) {
        return;
      }
      if (this.userInput.length > 0) {
        const fuse = this.fuseEvents.search(this.userInput);
        this.setFilteredEvents(fuse);
        this.$emit('searching', '');
      } else {
        this.$emit('searching', 'reset');
        this.setFilteredEvents(this.unfilteredEvents.data);
      }
    },

    redirectToSearch() {
      this.$router.push({ path: '/search' });
    },
    setSubmittedData() {
      if (this.searchInput.length > 0) {
        this.setFilteredEvents(this.fuseEvents.search(this.searchInput));
        this.setFilteredMuseums({ data: this.fuseMuseums.search(this.searchInput) });
      } else {
        this.setFilteredEvents(this.unfilteredEvents.data);
        this.setFilteredMuseums(this.unfilteredMuseums);
      }
      this.$emit('filtering', false);
    },
    handleSubmit() {
      this.$emit('filtering', true);
      console.log('handleSubmit: ' + this.searchInput);
      this.setSearchQuery(this.searchInput);
      if (this.location === 'pageheader') {
        this.redirectToSearch();
        this.$emit('onSubmit');
        this.$emit('filtering', true);
      }
    },
    initFuse() {
      console.log('initFuse: ');
      this.fuseEvents = new Fuse(this.unfilteredEvents.data, this.fuseEventOptions);
    },
    initFuseMuseums() {
      console.log('initFuseMuseums: ');
      this.fuseMuseums = new Fuse(this.unfilteredMuseums.data, this.useMuseumOptions);
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class InputField extends Vue {}
