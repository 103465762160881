/** ----------------------------------------
    Menus Builder Service
 ---------------------------------------- */

/**
 * Will help building menus
 */

export default class MenusBuilder {

    vue: any;

    /** ----------------------------------------
        Constructor
     ---------------------------------------- */

    constructor(vue: any) {
        this.vue = vue;
    }

    /** ----------------------------------------
        Get model routes
     ---------------------------------------- */

    private getMenuItems() {
        const configData = this.vue.$store.state.config.data;
        const menuItems = configData.menu.item;
        const result = [];

        // [TODO: Backend] 
        // It might be better to have the route stored in the menu item.
        // All the code below is just to extract the route.
        // If the routes exists in the menu items then we could just return the menuItems..
        // So there is not need for decoupling the code below

        menuItems.forEach((item: any) => {

            // Get item config
            const itemConfig = item['@config'];

            // Get model name
            let modelName = itemConfig.replace('model:', '');
            modelName = modelName.substring(0, 1).toUpperCase() + modelName.substring(1);

            // Get model config
            const modelConfig = configData.models[modelName];
            if (!modelConfig)
                return;

            // Get model route
            // const modelPath = `/${modelConfig.config.route}`;
            const modelPath = `/${modelName.toLowerCase()}`;

            // Add menu item to result
            result.push({
                title: item.title,
                icon: item.icon,
                path: modelPath
            })
        });

        return result;
    }

    public setMenuItems() {
        // const menuItems = this.getMenuItems();
        // this.vue.$store.dispatch('routes/setMenu', menuItems);
        return true;
    }
}