

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import LogoSvg from '@svg/Logo.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        LogoSvg
    },

    computed: {
        link() {
            return (this.to) ? '/home' : '/';
        }
    },
    methods: {
        hideMenu() {
            this.$emit('clicked', '');
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class Logo extends Vue {
    @Prop() to: string;
};

