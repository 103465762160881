/** ----------------------------------------
    Routes Builder Service
 ---------------------------------------- */

import auth from "@middleware/auth";
import log from "@middleware/log";

import router from "@router/router";
import { resetRouter } from "@router/router";

/**
 * Will help building routes
 */

export default class RoutesBuilder {
  vue: any;

  /** ----------------------------------------
        Constructor
     ---------------------------------------- */

  constructor(vue: any) {
    this.vue = vue;
  }

  /** ----------------------------------------
        Get model routes
     ---------------------------------------- */

  private _getModelRoutes() {
    const configData = this.vue.$store.state.routes.routes.data;
    // console.log('configData', configData);
    const models = new Map(Object.entries(configData));
    // console.log('models', models);
    const routes = [];

    models.forEach((model: any, key: string) => {
      // Get model route
      const modelPath = `/${model.uri.toLowerCase()}`;
      let route = this._getModelOverviewRoute(key, model, modelPath);
      routes.push(route);
    });

    return routes;
  }

  private _getModelOverviewRoute(key, model, modelPath) {
    // Set meta data
    let meta = {
      title: model.uri,
      id: model.id,
      middleware: [auth, log],
      reqAuth: model.guarded == true ? true : false
    };

    // Create route
    let route = {
      path: `/${model.uri}`,
      name: model.uri,
      meta: meta,
      component: () => import(`@view/templates/${model.component}.vue`),
      children: null
    };

    return route;
  }

  private _getModelEditRoute(key, meta) {
    return {
      path: ":id/edit",
      name: `${key}.edit`,
      component: Detail,
      meta: meta
    };
  }

  private _getModelViewRoute(key, meta) {
    return {
      path: ":id/view",
      name: `${key}.view`,
      component: Detail,
      meta: meta
    };
  }

  private _getModelCreateRoute(key, meta) {
    return {
      path: ":id/create",
      name: `${key}.create`,
      component: Detail,
      meta: meta
    };
  }

  private _getModelDeleteRoute(key, meta) {
    return {
      path: ":id/delete",
      name: `${key}.delete`,
      component: Detail,
      meta: meta
    };
  }

  private _getNewErrorRoutes() {
    const routes = [];

    let resolved = router.resolve(`/*`);

    // if (!resolved.route.matched.length) {
    routes.push({
      path: "/*",
      name: "404",
      component: () => import("@view/errors/404.vue"),
      meta: {
        middleware: [log]
      }
    });
    // }

    return routes;
  }

  public setRoutes() {
    // Build routes
    let routes = [];

    routes = routes.concat(this._getModelRoutes());
    routes = routes.concat(this._getNewErrorRoutes());

    // Set routes in router options
    const routerRoutes = router.options.routes;
    const masterRoute = routerRoutes.find(r => r.path === "*");
    masterRoute.children = masterRoute.children.concat(routes);
    masterRoute.children = masterRoute.children.filter(
      route => route.path !== "*"
    );

    // Get current route option
    let current = null;
    masterRoute.children.some(route => {
      if (route.path === this.vue.$route.path) {
        current = route;
        return;
      }
    });

    // Reset router (this will use the router options)
    resetRouter();

    // Push route
    router.push({ ...this.vue.$route });

    return true;
  }
}
