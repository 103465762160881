
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Mixins, Vue } from 'vue-property-decorator';
import { SlotsMixin } from '@fruitpackage/mixins';
import CrossCircleSvg from '@svg/CrossCircle.vue';
/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    CrossCircleSvg
  },
  props: {
    transparent: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    },
    visible: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    clickBg(event) {
      let clickTarget = event.target.classList[0];
      clickTarget == 'overlay' && this.closeOverlay();
    },
    closeOverlay() {
      this.$emit('clicked', '');
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Overlay extends Vue {}
