

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { AppStore } from '@interface/store/App';
import MenuSvg from '@svg/Menu.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        MenuSvg
    },
    data() {
        return {
            animating: false
        };
    },
    methods: {
        animate() {
            this.toggleMenu();
            if (!this.animating) {
                this.animating = true;
                setTimeout(() => {
                    this.animating = false;
                }, 1000);
            }
        },
        toggleMenu(event) {
            this.$emit('clicked', '');
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class MenuButton extends Vue {};

