
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import $ from 'jquery';
import slick from 'slick-carousel';
import uniqid from 'uniqid';
import ArrowBackSvg from '@svg/ArrowBack.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    data() {
        return {
            uniqueId: uniqid(),
            isHidden: true,
        };
    },
    components: {
        ArrowBackSvg
    },
    methods: {
        slide() {
            // for some reason stuff breaks if you dont have 'slick' here
            slick;
            $('.slider_' + this.uniqueId).slick({
                infinite: true,
                slidesToShow: 3,
                arrows: true,
                dots: false,
                slidesToScroll: 1,
                prevArrow: $(`.prev_${this.uniqueId}`),
                nextArrow: $(`.next_${this.uniqueId}`),
                autoplay: true,
                autoplaySpeed: 10000,
                variableWidth: false,
                adaptiveHeight: true
            });
        }
    },
    mounted() {
        let $this = this;
        setTimeout(() => {
            this.slide();
            this.isHidden = false;
        }, 700);
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class SliderFooter extends Vue {}
