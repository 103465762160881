
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import MenuSvg from '@svg/Search-large.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    MenuSvg
  },
  data() {
    return {
      animating: false
    };
  },
  methods: {
    animate() {
      this.toggleMenu();
      if (!this.animating) {
        this.animating = true;
        setTimeout(() => {
          this.animating = false;
        }, 1000);
      }
    },
    toggleMenu(event) {
      this.$emit('clicked', '');
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class SearchButton extends Vue {}
