
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { AppStore } from '@interface/store/App';
import { app } from '@config';
import Logo from '@part/elements/Logo';
import MenuButton from '@part/elements/MenuButton';
import SearchButton from '@part/elements/SearchButton';
import Overlay from '@part/layout/Overlay.vue';
import CrossCircleSvg from '@svg/CrossCircle.vue';
import InputField from '@part/elements/InputField.vue';

import Menu from '@part/layout/Menu';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  data() {
    return {
      menuVisible: false,
      searchOverlayVisible: false,
      dateLen: 0
    };
  },

  components: {
    Overlay,
    CrossCircleSvg,
    SearchButton,
    InputField,
    Logo,
    MenuButton,
    Menu
  },
  computed: {
    ...mapState(['navData']),
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    })
  },
  watch: {
    navData: function() {
      if (this.navData.date) {
        this.dateLen = this.navData.date.length;
      }
    }
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    closeOverlay() {
      this.searchOverlayVisible = false;
    },
    toggleSearchOverlay() {
      this.searchOverlayVisible = !this.searchOverlayVisible;
    },
    hideMenu() {
      this.menuVisible = false;
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class PageHeader extends Vue {}
