
/* ----------------------------------------
        Imports
    ---------------------------------------- */

import UserJob from '@job/UserJob';
import AppJob from '@job/AppJob';

import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { StoreMixin } from '@mixin/store';
import { handle429 } from '@util/util.ts';
import { app } from '@config';
import axios from 'axios';
import Overlay from '@part/layout/Overlay.vue';
import PageHeader from '@part/layout/PageHeader.vue';
import PageView from '@part/layout/PageView.vue';
import PageFooter from '@part/layout/PageFooter.vue';
import Toast from '@part/elements/Toast.vue';
import Login from '@part/layout/Login.vue';
import Stroke from '@part/features/Stroke.vue';
import CrossCircleSvg from '@svg/CrossCircle.vue';

/* ----------------------------------------
        Component
    ---------------------------------------- */

@Component({
    data() {
        return {
            hideFooter: false
        };
    },

    created() {
        if (this.isLoggedIn) {
            const userJob = new UserJob(this._vm, this);
            userJob.init();
        }
        this.$root.$on('root::layout::hideFooter', hideFooter => {
            this.hideFooter = hideFooter;
        });

        const appJob = new AppJob(this);
        appJob.init();
    },

    components: {
        Overlay,
        PageHeader,
        PageView,
        PageFooter,
        Toast,
        Login,
        Stroke,
        CrossCircleSvg
    },

    mixins: [StoreMixin],

    computed: {
        ...mapGetters({ isLoggedIn: 'user/isLoggedIn' }),
        ...mapState(['activeHearts', 'warningVisible', ]),
        currentRouteName() {
            return this.$route.name;
        }
    },
    watch: {
        isLoggedIn: function (val) {
            this.getActiveHearts();
        }
    },
    methods: {
        ...mapMutations(['setActiveHearts', 'setStartLocation', 'setNavData', 'setCookieBannerStrings', 'toggleWarning', 'triggerAcceptWarning', 'setEventType']),
        getActiveHearts() {

            if (!this.isLoggedIn) {
                return;
            }
            const instance = axios.create({
                baseURL: app.api.auth,
                timeout: 2500,
                headers: {
                    Authorization: 'Bearer ' + this.user.token
                }
            });

            instance.get('/route').then(response => {
                if (response.data.data.museums[0].routables[0]) {
                    this.setStartLocation(response.data.data.museums[0].routables[0]);
                    return response.data;
                }
            }).catch(error => {
                console.log(`🔥${error}🔥`);
                handle429(this, error);
            });

            instance.get('/route/hearts').then(response => {
                this.setActiveHearts(response.data);
                return response.data;
            }).catch(error => {
                console.log(`🔥${error}🔥`);
                handle429(this, error);
            });
        },
        getNavData() {
            axios
                .get(`${app.api.api}/page/master`)
                .then(response => {
                    this.setCookieBannerStrings(response.data.cookiebanner);
                    this.setNavData(response.data);
                    this.setEventType(response.data.event_type);
                })
                .catch(error => {
                    console.log(`🔥${error}🔥`);
                    handle429(this, error);
                });
        },
        closeWarning() {
            this.toggleWarning(false);
        },
        acceptWarning() {
            this.triggerAcceptWarning(true);
            this.toggleWarning(false);
        }
    },
    mounted() {
        this.getActiveHearts();
        this.getNavData();
    }
})

/* ----------------------------------------
        Export
    ---------------------------------------- */
export default class Master extends Vue {}
